import Vue from "vue";
import VueRouter from "vue-router";
import { isUserLoggedIn } from "@/auth/utils";
import { getAuth, onAuthStateChanged } from "firebase/auth";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      redirect: { name: "login" },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: () => import("@/views/Dashboard.vue"),
    },
    {
      path: "/consultants",
      name: "consultants",
      component: () => import("@/views/Consultants.vue"),
      meta: {
        pageTitle: "Consultants",
        breadcrumb: [
          {
            text: "Dashboard",
            to: "/dashboard",
            active: false,
          },
          {
            text: "Consultants",
            active: true,
          },
        ],
      },
    },
    {
      path: "/consultants/:consultant",
      name: "consultant-details",
      component: () => import("@/views/ConsultantDetails.vue"),
      meta: {
        pageTitle: "Consultant Details",
        breadcrumb: [
          {
            text: "Dashboard",
            to: "/dashboard",
            active: false,
          },
          {
            text: "Consultants",
            to: "/consultants",
            active: false,
          },
          {
            text: "Consultant Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/payments",
      name: "payments",
      component: () => import("@/views/Payments.vue"),
      meta: {
        pageTitle: "Payments",
        breadcrumb: [
          {
            text: "Dashboard",
            to: "/dashboard",
            active: false,
          },
          {
            text: "Payments",
            active: true,
          },
        ],
      },
    },
    {
      path: "/leads",
      name: "leads",
      component: () => import("@/views/Leads.vue"),
      meta: {
        pageTitle: "Leads",
        breadcrumb: [
          {
            text: "Dashboard",
            to: "/dashboard",
            active: false,
          },
          {
            text: "Leads",
            active: true,
          },
        ],
      },
    },
    {
      path: "/leads/:leadID",
      name: "lead-details",
      component: () => import("@/views/LeadDetails.vue"),
      meta: {
        pageTitle: "Lead Detaiils",
        breadcrumb: [
          {
            text: "Dashboard",
            to: "/dashboard",
            active: false,
          },
          {
            text: "Leads",
            to: "/leads",
            active: false,
          },
          {
            text: "Lead Details",
            active: true,
          },
        ],
      },
    },
    {
      path: "/leads/:leadID/edit",
      name: "lead-edit",
      component: () => import("@/views/LeadEdit.vue"),
    },
    {
      path: "/admins",
      name: "admins",
      component: () => import("@/views/Admins.vue"),
      meta: {
        pageTitle: "Admins",
        breadcrumb: [
          {
            text: "Dashboard",
            to: "/dashboard",
            active: false,
          },
          {
            text: "Admins",
            active: true,
          },
        ],
      },
    },
    {
      path: "/services",
      name: "services",
      component: () => import("@/views/Services.vue"),
      meta: {
        pageTitle: "Services",
        breadcrumb: [
          {
            text: "Dashboard",
            to: "/dashboard",
            active: false,
          },
          {
            text: "Services",
            active: true,
          },
        ],
      },
    },
    {
      path: "/designations",
      name: "designations",
      component: () => import("@/views/Designations.vue"),
      meta: {
        pageTitle: "Designations",
        breadcrumb: [
          {
            text: "Dashboard",
            to: "/dashboard",
            active: false,
          },
          {
            text: "Designations",
            active: true,
          },
        ],
      },
    },
    {
      path: "/sectors",
      name: "sectors",
      component: () => import("@/views/Sectors.vue"),
      meta: {
        pageTitle: "Sectors",
        breadcrumb: [
          {
            text: "Dashboard",
            to: "/dashboard",
            active: false,
          },
          {
            text: "Sectors",
            active: true,
          },
        ],
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
        redirectIfLoggedIn: true,
      },
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

// Define the navigation guard
router.beforeEach((to, from, next) => {
  const auth = getAuth();
  const isLoggedIn = auth.currentUser;

  if (to.name === "login" && !isLoggedIn) {
    // Allow visiting the login page if not logged in
    next();
  } else if (to.meta.public) {
    // If the route is public, proceed
    next();
  } else if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    // If the route should redirect if logged in, and the user is logged in, redirect to /smart-link
    next("/dashboard");
  } else {
    // If the route is protected, check if the user is logged in
    if (isLoggedIn) {
      next();
    } else {
      // If not logged in, wait for auth state to be determined
      onAuthStateChanged(auth, (user) => {
        if (user) {
          next();
        } else {
          next("/login");
        }
      });
    }
  }
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
