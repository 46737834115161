import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD_yoMI1eNwTBqUfokB2l6B880rRJe95ro",
  authDomain: "appmein-6b9b5.firebaseapp.com",
  projectId: "appmein-6b9b5",
  storageBucket: "appmein-6b9b5.appspot.com",
  messagingSenderId: "432976191971",
  appId: "1:432976191971:web:178ea36af0279210a8c0d6",
  measurementId: "G-PNHX8V1TK0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const database = getFirestore(app);

export { app, database };
