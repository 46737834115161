import { getAuth } from "firebase/auth";

export default {
  install: (Vue) => {
    Vue.prototype.$getUserToken = () => {
      const auth = getAuth();
      const user = auth.currentUser;
      return user ? user.accessToken : null;
    };
  },
};
