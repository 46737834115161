import { $themeConfig } from '@themeConfig'
import Cookies from "js-cookie";

export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    recentlyViewedClients: Cookies.get("recentlyViewedClients") ? JSON.parse(Cookies.get("recentlyViewedClients")) : [],
    menuItems: [
      {
        header: 'Dashboard',
      },
      {
        title: 'Overview',
        route: 'dashboard',
        icon: 'GridIcon',
      },
      {
        title: 'Triggers',
        route: 'triggers',
        icon: 'ZapIcon',
      },
      {
        title: 'Access Control',
        route: 'access-control',
        icon: 'UnlockIcon',
      },
      {
        header: 'Recently Visited Clients',
      },
    ],
  },
  getters: {
    recentlyViewedClients(state) {
      return state.recentlyViewedClients
    },
    menuItems(state) {
      return state.menuItems.concat(state.recentlyViewedClients);
    }
  },
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    UPDATE_CLIENT_DETAILS(state, data) {
      let recentlyViewedClients = state.recentlyViewedClients;
      let clientIndex = recentlyViewedClients.findIndex((client) => {
        return data.slug === client.slug;
      })
      if (clientIndex > -1) {
        const oldIndex = clientIndex;
        const newIndex = 0;
        if (oldIndex != newIndex) {
          let temp = recentlyViewedClients.splice(oldIndex, 1);
          recentlyViewedClients.splice(newIndex, 0, temp[0]);
        }
        Cookies.set("recentlyViewedClients", JSON.stringify(recentlyViewedClients));
        return;
      }
      if (state.recentlyViewedClients.length === 5) {
        state.recentlyViewedClients.splice(4, 1);
      }
      state.recentlyViewedClients.unshift({
        title: data.name,
        icon: 'ZapIcon',
        client: true,
        to: `/client/${data.slug}`,
        slug: data.slug
      });

      Cookies.set("recentlyViewedClients", JSON.stringify(state.recentlyViewedClients));
    }
  },
  actions: {},
}
