import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/clipboard";

// Firebase
import { getAuth, onAuthStateChanged } from "firebase/auth";
import AuthPlugin from "@/plugins/authPlugin";
import DateFormatPlugin from "@/plugins/formatDate";
import CommaNumberPlugin from "@/plugins/commaNumber";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// Use the custom auth plugin
Vue.use(AuthPlugin);
Vue.use(DateFormatPlugin);
Vue.use(CommaNumberPlugin);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

let app;
const auth = getAuth();

onAuthStateChanged(auth, (user) => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
